import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { PlusIcon, DocumentIcon, TrashIcon } from '@heroicons/react/24/solid';
import { useDocument } from '../hooks/useDocument';
import DeleteConfirmationPopup from './DeleteConfirmationPopup';
import AnimatedSearchBar from './AnimatedSeachBar';
// import DeleteConfirmationPopup from './DeleteConfirmationPopup';


const HomeScreen = () => {
  const [filter, setFilter] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');  // Add this state
  const { allDocuments, isLoadingAll, isErrorAll, deleteDocument } = useDocument();
  const [deletePopupOpen, setDeletePopupOpen] = useState(false);
  const [documentToDelete, setDocumentToDelete] = useState(null);

  // useEffect(() => {
  //   if (allDocuments) {
  //     filterDocuments(filter);
  //   }
  // }, [allDocuments, filter]);

  const filteredDocuments = useMemo(() => {
    if (!allDocuments) return [];
    
    let filtered = allDocuments;

    // First apply filters
    switch (filter) {
      case 'shared':
        filtered = filtered.filter(doc => doc.shareable_link && !doc.signed_by);
        break;
      case 'drafted':
        filtered = filtered.filter(doc => !doc.shareable_link);
        break;
      case 'signed':
        filtered = filtered.filter(doc => doc.signed_by);
        break;
      default:
        break;
    }

    // Then apply search if there is a search term
    if (searchTerm) {
      filtered = filtered.filter(doc =>
        doc.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (doc.description && doc.description.toLowerCase().includes(searchTerm.toLowerCase()))
      );
    }

    return filtered;
  }, [allDocuments, filter, searchTerm]);


  // Removed isLinkExpired since documents don't expire
  /*
  const isLinkExpired = (expiryDate) => {
    return expiryDate && new Date(expiryDate) < new Date();
  };
  */

  const handleDeleteClick = (document) => {
    setDocumentToDelete(document);
    setDeletePopupOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (documentToDelete) {
      try {
        await deleteDocument(documentToDelete._id);
        setDeletePopupOpen(false);
        setDocumentToDelete(null);
      } catch (error) {
        console.error('Error deleting document:', error);
        alert('Failed to delete the document. Please try again.');
      }
    }
  };

  if (isLoadingAll) {
    return <div className="text-center mt-8">Loading documents...</div>;
  }

  if (isErrorAll) {
    return <div className="text-center mt-8 text-red-500">Error loading documents. Please try again later.</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="text-center mb-8">
        <h1 className="text-3xl font-bold text-grayteck-900 dark:text-grayteck-100 mb-4">
          Welcome to GT DocSign
        </h1>
        <p className="text-xl text-grayteck-700 dark:text-grayteck-300">
          Manage and sign your documents with ease
        </p>
      </div>

      {/* Responsive Filter and Create Button Layout */}
      <div className="mb-8 flex flex-col sm:flex-row sm:justify-between sm:items-center space-y-4 sm:space-y-0">
      {/* Filter Buttons - Horizontal scroll on mobile */}
        {/* Filter Buttons and Search Bar Container */}
  <div className="flex flex-wrap items-center gap-4">
        {/* Existing Filter Buttons */}
        <div className="flex overflow-x-auto pb-2 sm:pb-0 -mx-4 sm:mx-0 px-4 sm:px-0">
          <div className="flex space-x-2 min-w-max">
            <button
              onClick={() => setFilter('all')}
              className={`px-4 py-2 rounded-md whitespace-nowrap ${
                filter === 'all' 
                  ? 'bg-grayteck-600 text-white' 
                  : 'bg-grayteck-200 text-grayteck-800'
              }`}
            >
              All
            </button>
            <button
              onClick={() => setFilter('shared')}
              className={`px-4 py-2 rounded-md whitespace-nowrap ${
                filter === 'shared' 
                  ? 'bg-grayteck-600 text-white' 
                  : 'bg-grayteck-200 text-grayteck-800'
              }`}
            >
              Shared
            </button>
            <button
              onClick={() => setFilter('drafted')}
              className={`px-4 py-2 rounded-md whitespace-nowrap ${
                filter === 'drafted' 
                  ? 'bg-grayteck-600 text-white' 
                  : 'bg-grayteck-200 text-grayteck-800'
              }`}
            >
              Drafted
            </button>
            <button
              onClick={() => setFilter('signed')}
              className={`px-4 py-2 rounded-md whitespace-nowrap ${
                filter === 'signed' 
                  ? 'bg-grayteck-600 text-white' 
                  : 'bg-grayteck-200 text-grayteck-800'
              }`}
            >
              Signed
            </button>
          </div>
        </div>

    {/* Search Bar */}
    <AnimatedSearchBar onSearch={setSearchTerm} />
  </div>

        {/* Create Button */}
        <Link
          to="/create"
          className="flex items-center justify-center bg-grayteck-600 hover:bg-grayteck-700 text-white font-bold py-2 px-4 rounded transition-colors duration-200"
        >
          <PlusIcon className="h-5 w-5 mr-2" />
          <span className="whitespace-nowrap">Create Document</span>
        </Link>
      </div>

      <AnimatePresence mode="wait">
        {filteredDocuments.length === 0 ? (
          <motion.div
            key="empty"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
            className="text-center py-12"
          >
            <p className="text-xl italic text-grayteck-600 dark:text-grayteck-400">
              No documents found. Create a new document to get started!
            </p>
          </motion.div>
        ) : (
          <motion.div
            key="grid"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="grid grid-cols-1 gap-4 sm:gap-6"
          >
            {filteredDocuments.map((doc) => (
              <motion.div
                key={doc._id}
                layout
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3 }}
                className="bg-white dark:bg-darkbg-800 rounded-lg shadow-md p-4 sm:p-6 overflow-hidden"
              >
                <div className="flex items-center justify-between mb-4">
                  <div className="flex items-center min-w-0 flex-1">
                    <DocumentIcon className="h-6 w-6 sm:h-8 sm:w-8 text-grayteck-600 dark:text-grayteck-400 mr-3 flex-shrink-0" />
                    <h2 className="text-lg sm:text-xl font-semibold text-grayteck-900 dark:text-grayteck-100 truncate">
                      {doc.title}
                    </h2>
                  </div>
                  <motion.button
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    onClick={() => handleDeleteClick(doc)}
                    className="text-red-500 hover:text-red-700 transition-colors duration-200"
                  >
                    <TrashIcon className="h-5 w-5" />
                  </motion.button>
                </div>
                <div className="max-w-full"> {/* Added container for description */}
                <p className="text-grayteck-700 dark:text-grayteck-300 mb-4 text-sm sm:text-base">
                  {doc.description || 'No description provided'}
                </p>
                </div>
                <div className="flex justify-between items-center">
                  {/* Conditional Link Rendering */}
                  <div className="min-w-0 flex-1"> {/* Added container for link */}
                  {doc.signed_by ? (
                    // If the document is signed, show "View Document" button
                    <Link 
                      to={`/view/${doc._id}`} // Change to your view route if different
                      className="text-grayteck-600 hover:text-grayteck-800 dark:text-grayteck-400 dark:hover:text-grayteck-200 font-medium text-sm sm:text-base truncate block"
                    >
                      View Document
                    </Link>
                  ) : (
                    // If the document is not signed, show "Edit Document" button
                    <Link 
                      to={`/edit/${doc._id}`}
                      className="text-grayteck-600 hover:text-grayteck-800 dark:text-grayteck-400 dark:hover:text-grayteck-200 font-medium text-sm sm:text-base"
                    >
                      Edit Document
                    </Link>
                  )}
                  </div>
                  
                  <div className="flex items-center space-x-2 ml-2 flex-shrink-0">
                    {doc.signed_by ? (
                      <span className="text-xs sm:text-sm text-blue-500">
                        Signed
                      </span>
                    ) : doc.shareable_link ? (
                      <span className="text-xs sm:text-sm text-green-500">
                        Shared
                      </span>
                    ) : null}
                  </div>
                </div>
              </motion.div>
            ))}
          </motion.div>
        )}
      </AnimatePresence>

      <DeleteConfirmationPopup
        isOpen={deletePopupOpen}
        onClose={() => setDeletePopupOpen(false)}
        onConfirm={handleDeleteConfirm}
        documentTitle={documentToDelete?.title}
      />
    </div>
  );
};

export default HomeScreen;